<template>
  <v-container>
    <v-row>
      <v-tabs v-model="tab" background-color="transparent" grow>
        <v-tab v-for="item in items" :key="item" :to="link_tab(item)">
          {{ item.content }}
        </v-tab>
      </v-tabs>
    </v-row>
    <v-row class="text-center">
      <v-data-table
        :headers="headers"
        :items="lessonProcess"
        class="elevation-1 mx-auto mt-5"
      >
        <template v-slot:item.course.number_month_expired="{ item }">
          <div v-if="is_edit_expired_date != item.id">
            {{ item.expired_date }} tháng <span @click="is_edit_expired_date = item.id"><v-icon small color="orange" >mdi-pencil</v-icon></span>
          </div>
          <div v-else>
            <input class="border text-center w-25" type="number" v-model="item.expired_date" @keypress.enter="change_expired_date(item)"> <span @click="change_expired_date(item)"><v-icon small color="green">mdi-check-bold</v-icon></span><span @click="is_edit_expired_date = null"><v-icon small color="red">mdi-close-circle</v-icon></span>
          </div>
        </template>
        <template v-slot:item.course.process.percent="{ item }">
          <v-progress-linear :value="item.course && item.course.process ? item.course.process.percent : 0" height="25">
            <strong>{{ Math.ceil(item.course && item.course.process ? item.course.process.percent : 0) }}%</strong>
          </v-progress-linear>
        </template>
      </v-data-table>
    </v-row>
  </v-container>
</template>

<script>


import { GET_PURCHASE_COURSE_LIST, CHANGE_EXPIRED_DATE } from "@/store/courses.module";
export default {

  data: () => {
    return {
      tab: 0,
      items: [
        {
          content: "Chi tiết học viên",
          link: "/user/student/",
          path: "info"
        },
        {
          content: "Các khóa đã học",
          link: "/user/student/",
          path: "course"
        },
        {
          content: "Khóa giao tiếp 1-1",
          link: "/user/student/",
          path: "OneOneCourse"
        },
        {
          content: "Các bài thi",
          link: "/user/student/",
          path: "takeExam"
        }
      ],
      headers: [
        {
          text: "Khóa học",
          align: "start",
          sortable: false,
          value: "course.title"
        },
        { text: "Thời gian đăng ký", value: "active_date" },
        { text: "Thời hạn học", value: "course.number_month_expired" },
        { text: "Hoàn thành", value: "course.process.percent" }
      ],
      is_edit_expired_date: null,
    };
  },
  computed: {
    lessonProcess() {
      return this.$store.getters.purchaseCourseList;
    }
  },
  created() {
    let user_id = this.$route.params.id;
    this.getLessonProcess(user_id);
  },
  methods: {
    link_tab(link) {
      return link.link + this.$route.params.id + "/" + link.path;
    },
    getLessonProcess(user_id) {
      this.$store.dispatch(GET_PURCHASE_COURSE_LIST, { user_id: user_id });
    },
    change_expired_date(item){
      let payload = {
        id: item.id,
        expired_date: item.expired_date,
      }
      this.$store.dispatch(CHANGE_EXPIRED_DATE, payload).then(data=>{
        if (data.status) {
          this.getLessonProcess(this.$route.params.id);
          this.is_edit_expired_date = null
          this.$forceUpdate()
        }
      })
    }
  }
};
</script>

<style scoped>
.theme--light.v-data-table {
  background-color: #ffffff;
  color: rgba(0, 0, 0, 0.87);
  width: 90% !important;
}

.v-application .primary {
  background-color: limegreen !important;
}
</style>
